import React from "react";

const Feature = ({icon, title ,inverted}) => {
    return (
        <div className={`feature mb-4 ${inverted && 'inverse'}`}>
            <div className="icon">
                <i className={icon}></i>
            </div>
            <h4>{title}</h4>
        </div>
    );
}

export default Feature;