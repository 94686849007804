import React from "react"

const ContactPhone = () => {
    return (
        <>
            <div className="phone">
                <h4>Veuillez appeler les numéros suivants</h4>
                <ul>
                    <li> <i className="bi bi-telephone-fill"></i> +261 34 61 112 63</li>
                    <li> <i className="bi bi-telephone-fill"></i> +261 32 69 864 11</li>
                </ul>
            </div>
        </>
    )
}
export default ContactPhone