import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, InputGroup, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";

import CreatableSelect from 'react-select/creatable';
import productService from "services/productService";
import { formatMGA } from "utils/NumberFormatter";
import { useParams } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import quillConfig from "config/quillConfig";

const StepSupplierProduct = ({supplierProductsData, setSupplierProductsData, errors}) => {
    const { projectId } = useParams();
    const [activeKey, setActiveKey] = useState(undefined);
    const [productOptions, setProductOptions] = useState([]);
    const [formNotice, setFormNotice] = useState("");
    const [formData, setFormData] = useState({
        name: null,
        description: "",
        notices: [],
        unit_price: 0
    });

    const quillModule = quillConfig.getQuillModuleForProductSupplier;
    const quillFormat = quillConfig.getQuillFormatForProductSupplier;

    const selectProductRef = useRef(null);
    const handleSelectChange = (selectedOption, actionMeta) => {
        setFormData({
            ...formData,
            name: selectedOption
        });
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleAddProduct = () => {
        if (formData.name === null) return;
        const product = {
            name: formData.name?.value || "",
            description: formData.description,
            notices: formData.notices,
            unit_price: formData.unit_price
        };
        setSupplierProductsData([...supplierProductsData, product]);
        setFormData({
            name: null,
            description: "",
            notices: [],
            unit_price: 0
        });
        selectProductRef.current.focus();
    }

    const handleDeleteProduct = (index) => {
        const temp = [...supplierProductsData];
        temp.splice(index, 1);
        setSupplierProductsData(temp);
    }

    useEffect(() => {
        productService.getProductByProject(projectId)
        .then(result => {
            const options = result.map(item =>  ({
                value: item.name,
                label: item.name
            }));
            setProductOptions(options);
        })
        .catch(error => console.error(error));
    }, [projectId]);


    const deleteNotice = (index) => {
        const temp = [...formData.notices];
        temp.splice(index, 1);
        setFormData({
            ...formData,
            notices: temp
        });
    }

    const handleToggle = (key) => {
        setActiveKey(activeKey === key ? null : key);
    };

    const addNotice = () => {
        if (formNotice === null) return;
        setFormData({
            ...formData,
            notices: [...formData.notices, formNotice]
        })
        setFormNotice("");
    }

    const showError = (field) => {
        if (errors[field] && errors[field].length > 0) {
          return errors[field][0]; 
        }
        return null;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleAddProduct();
    }

    return (
        <>
            <Row className="supplier-information">
                <Col md={4}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="StepSupplierInformationName" className="mb-2">
                            <Form.Label>
                                <span> <i className="bi bi-cart"></i> </span>
                                Produit
                            </Form.Label>
                            <CreatableSelect
                                isClearable
                                options={productOptions}
                                onChange={handleSelectChange}
                                placeholder="Choisissez un produit ..."
                                noOptionsMessage={() => "Aucun produit disponible"}
                                loadingMessage={() => "Chargement..."} 
                                name="name"
                                value={formData.name}
                                autoFocus
                                formatCreateLabel={(inputValue) => `Nouveau "${inputValue}"`}
                                ref={selectProductRef}
                                />
                        </Form.Group>

                        <Form.Group controlId="StepSupplierInformationName" className="mb-2">
                            <Form.Label>
                                <span> <i className="bi bi-info-circle"></i> </span>
                                Description
                            </Form.Label>
                            
                            <ReactQuill
                                theme="snow"
                                value={formData.description}
                                onChange={(value) => setFormData({...formData, description: value})}
                                modules={quillModule}
                                formats={quillFormat}
                                placeholder="Écrivez la description ici..."
                            />
                        </Form.Group>

                        <Form.Group controlId="StepSupplierInformationUnitPrice" className="mb-2">
                            <Form.Label>
                                <span> <i className="bi bi-credit-card"></i> </span>
                                Prix
                            </Form.Label>
                            <InputGroup >
                                <Form.Control
                                type="number"
                                placeholder="Prix"
                                name="unit_price"
                                value={formData.unit_price}
                                onChange={handleInputChange}
                                min={0}
                                step={0.01}
                                isInvalid={!!showError('unit_price')}
                                />
                                <InputGroup.Text id="InputGroupUnitPrice">Ar</InputGroup.Text>
                                <Form.Control.Feedback type="invalid">
                                    {showError('unit_price')}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group controlId="NoticeForm" className="mb-3 form-notices">
                            <Form.Label>
                                <span> 
                                    <i className="bi bi-chat-dots"></i> Vos avis
                                </span>
                            </Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control
                                value={formNotice}
                                onChange={e => setFormNotice(e.target.value)}
                                placeholder="Nouvel avis ..."
                                autoFocus
                                />
                                <Button variant="outline-secondary" id="button-addon2" onClick={addNotice}>
                                Ajouter
                                </Button>
                            </InputGroup>
                            <ul>
                                {formData.notices.map((item, index) => 
                                    <li key={index} className="mb-3">
                                        <span>
                                            {item}
                                        </span>
                                        <i className="bi bi-trash" onClick={() => deleteNotice(index)}></i> 
                                    </li>
                                )}
                            </ul>
                        </Form.Group>
                        <button className="asako-btn asako-btn-primary w-100">Ajouter</button>
                    </Form>
                </Col>
                <Col md={8}>
                    <Table striped bordered className="product-table">
                        <thead>
                            <tr>
                                <th className="name">Produit</th>
                                <th className="unit_price">Prix (Ar)</th>
                                <th className="notices">Avis</th>
                                <th className="action-icons">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {supplierProductsData.length === 0 && <tr><td colSpan={5}>Veuillez ajouté un produit</td></tr>}
                            {supplierProductsData.map((product, index)=> 
                            <React.Fragment key={index}>
                                <tr>
                                    <td className="name">{product.name}</td>
                                    <td className="unit_price">{formatMGA(product.unit_price)}</td>
                                    <td className="notices">
                                        <ul>
                                            {product.notices.map((notice, index) => <li key={index}>{notice}</li>)}
                                        </ul>
                                    </td>
                                    <td className="action-icons">
                                        <OverlayTrigger
                                        overlay={<Tooltip>{activeKey === index ? 'Cacher la description' : 'Voir la description'}</Tooltip>}
                                        placement="bottom"
                                        >
                                            <div className="description" onClick={() => handleToggle(index)}>
                                                <i className="bi bi-justify-left"></i>
                                            </div>
                                        </OverlayTrigger>
                                        <div className="remove" onClick={() => handleDeleteProduct(index)}>
                                            <i className="bi bi-x-lg"></i>
                                        </div>
                                    </td>
                                </tr>
                                {activeKey === index && (
                                <tr>
                                    <td colSpan="4">
                                        <Card>
                                            <Card.Body>
                                            <div
                                                dangerouslySetInnerHTML={{ __html: product.description }}
                                            />
                                            </Card.Body>
                                        </Card>
                                    </td>
                                </tr>
                                )}
                            </React.Fragment>
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    );
}

export default StepSupplierProduct;