import React, { useCallback, useEffect, useState } from "react";

import SupplierCard from "../../components/suppliers/SupplierCard";

import Select from "react-select";
import { Col, Row } from "react-bootstrap";
import productService from "services/productService";
import { Link } from "react-router-dom";
import '../../assets/css/admin/supplier-page.scss';
import supplierService from "services/supplierService";
import { useParams } from "react-router-dom";
import { useProfil } from "contexts/ProfilContext";
import AsakoPagination from "components/AsakoPagination";
import SupplierCardLoader from "components/suppliers/SupplierCardLoader";

const SupplierPage = () => {
    const { projectId } = useParams();
    const { isAdmin } = useProfil();
    const [page, setPage] = useState(1);
    const [onInitLoading, setOnInitLoading] = useState(true);
    const [onLoading, setOnLoading] = useState(true);
    const [totalSupplier, setTotalSupplier] = useState(0);
    const [pagination, setPagination] = useState({
        per_page: 1,
        total: 0,
        current_page: 1,
        last_page: 1
    });
    const [productOptions, setProductOptions] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [productIds, setProductIds] = useState([]);
    const [admin, setAdmin] = useState(false);

    useEffect(() => {
        isAdmin(projectId)
        .then(result => setAdmin(result))
        .catch(error => console.log(error));
    }, []);

    const hasSupplier = () => {
        return totalSupplier > 0;
    }

    useEffect(() => {
        productService.getProductByProject(projectId)
        .then(result => {
            const options = result.map(item =>  ({
                value: item.id,
                label: item.name
            }));
            setProductOptions(options);
        })
        .catch(error => console.error(error));
        
    }, [projectId]);

    const changeSupplier = useCallback((newPage, init = false) => {
        setOnLoading(true);
        supplierService.getSupplierByProductsPaginated(productIds, newPage, projectId)
        .then(result => {
            if (init) {
                setTotalSupplier(result.total);
            }
            setSuppliers(result.data);
            setPagination(result);
            setOnInitLoading(false);
        })
        .catch(error => console.error(error))
        .finally(() => setOnLoading(false));
    }, [productIds, projectId]);
    
    useEffect(() => {
        changeSupplier(page, true);
    }, [projectId]);

    useEffect(() => {
        changeSupplier(page);
    }, [page, changeSupplier])

    const handleChangeOptions = (selectedOptions) => {
        let productId = [];
        if (selectedOptions !== null) {
            selectedOptions.forEach(item => {
                productId.push(item.value);
            });
        }
        setProductIds(productId);
        setSelectedProducts(selectedOptions);
        setPage(1);
    }



    return (
        <>
            <div className="suppliers-container">
                <h1>Fournisseurs</h1>
                <p className="description">
                    <em>
                        ”Un fournisseur est <span>une entreprise ou une personne</span> qui vend <span>des biens ou des services</span> qui vous sont utiles. Les fournisseurs jouent un rôle clé dans <span>la chaîne d'approvisionnement</span>, car ils fournissent <span>les produits ou services</span> nécessaires”
                    </em>
                </p>
                {onInitLoading ? 
                <Row>
                    <Col md={3}>
                        <SupplierCardLoader />
                    </Col>
                    <Col md={3}>
                        <SupplierCardLoader />
                    </Col>
                </Row>
                :
                <>
                    {hasSupplier() ? 
                    <>
                        <Row>
                            <Col md={4}>
                                <Select
                                    placeholder="Choisir les produits "
                                    isMulti
                                    options={productOptions}
                                    value={selectedProducts}
                                    onChange={handleChangeOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    noOptionsMessage= {() => "Aucun produit disponible"}
                                    />
                            </Col>
                            <Col md={3}></Col>
                            {admin && 
                            <Col md={3}>
                                <Link to={`/projects/${projectId}/add-suppliers`}>
                                    <button className="asako-btn asako-btn-primary">Nouveau fournisseur</button>
                                </Link>
                            </Col>
                            }
                        </Row>
                        <Row>
                            {onLoading ? 
                            <Col md={3}>
                                <SupplierCardLoader />
                            </Col>
                            :
                            <>
                                {suppliers.map(supplier =>
                                    <Col md={3} key={supplier.id}>
                                        <SupplierCard data={supplier}/>
                                    </Col> 
                                )}
                            </>
                            }
                        </Row>
                        <AsakoPagination 
                        page={page}
                        pagination={pagination}
                        />
                        </>
                        :
                        <>
                        {admin && 
                        <Col md={3}>
                            <Link to={`/projects/${projectId}/add-suppliers`}>
                                <button className="asako-btn asako-btn-primary">Nouveau fournisseur</button>
                            </Link>
                        </Col>
                        }
                        <p className="mt-4"> <i className="bi bi-dash-circle"></i> Aucun fournisseur disponible</p>
                        </>
                    }
                </>
                }
            </div>
        </>
    )
}
export default SupplierPage;