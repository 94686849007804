import React from "react";
import { Col, Row } from "react-bootstrap";
import Feature from "./Feature";


const FeatureList = () => {
    const features = [
        {icon: 'bi bi-list-check', title: 'Tâches', inversed: true},
        {icon: 'bi bi-gear', title: 'Gestions', inversed: true},
        {icon: 'bi bi-people', title: 'Clients', inversed: true},
        {icon: 'bi bi-graph-up-arrow', title: 'Évolutions', inversed: true},
    ]

    return (
        <>
            <div className="features-container">
                <div className="title-container">
                    <h3>
                        Gérer vos <span>projets</span> et votre <span>équipes</span> efficacement
                    </h3>
                </div>
                <Row>
                    {features.map((feature, index) => 
                    <Col key={index}>
                        <Feature icon={feature.icon} title={feature.title} inverted={feature.inversed}/>
                    </Col>
                )}
                </Row>
                <div id="about"/>
            </div>
        </>

    )
}

export default FeatureList;