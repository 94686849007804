import getAxiosInstance from "config/axiosConfig";

import axiosNotAuthentified from 'config/axiosNotAuthentified';

const clientService = {

    resetPassword: async (data) => {
        const response = await axiosNotAuthentified.post(`/v1/auth/reset-password`, data);
        localStorage.setItem("asakoTokenId", response.data.access_token);
    },

    getProfil: async (projectId) => {
        const response = await getAxiosInstance().get(`/v1/projects/${projectId}/profil`);
        return response.data
    },

    login: async (email, password) => {
        const credentials = { "mail": email, "password": password };
        const response = await axiosNotAuthentified.post(`/v1/auth/login`, credentials);
        localStorage.setItem("asakoTokenId", response.data.access_token);
        return true;
    },

    register: async (data) => {
        try {
            await axiosNotAuthentified.post(`/v1/auth/register`, data);
            return true;
        } catch (error) {
            throw error;
        }
    },

    logout: async () => {
        localStorage.removeItem("asakoTokenId");
        sessionStorage.removeItem("AsakoProject");
        window.location.href="/login";
    },

    connected () {
        return !!localStorage.getItem('asakoTokenId');
    },

    me: async () => {
        const response = await getAxiosInstance().post(`v1/auth/me`);
        return response.data;
    },

    getToken: async () => {
        return localStorage.getItem('asakoTokenId');
    }
}


export default clientService;