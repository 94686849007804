import React from "react";
import { Form } from "react-bootstrap";

const ContactEmail = () => {
    return (
        <>
            <Form className="mail"> 
                <Form.Group className="mb-3" controlId="contactForm.mail">
                    <Form.Label>Votre email</Form.Label>
                    <Form.Control type="email" placeholder="nom@exemple.com" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="contactForm.question">
                    <Form.Label>Votre question</Form.Label>
                    <Form.Control 
                    as="textarea" 
                    rows={10} 
                    placeholder="Écrivez votre question ici ..."
                    />
                </Form.Group>
                <div className="submit-group">
                    <button type="submit" className="asako-btn asako-btn-primary">Envoyer</button>
                </div>
            </Form>
        </>
    )
}
export default ContactEmail