
import React from "react";
import "../../assets/css/client/home/offerBox.css"

const OfferBox = ({ data }) => {
    
    return (
        <>
            <div className="offer__card">
                <div className="header">
                    <h1>{data.name}</h1>
                </div>
                <div className="body">
                    <ul>
                        {data.products.map((product, index) => 
                            <li key={index}> <i className="bi bi-check-circle"></i> {product}</li>
                        )}
                    </ul>
                </div>
                <div className="footer">
                    <button className="asako-btn asako-btn-primary">Commander</button>
                </div>
            </div>
        </>
    )

}

export default OfferBox