import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const StepSupplierInformation = ({formData, handleChange, errors}) => {

    const showError = (field) => {
        if (errors[field] && errors[field].length > 0) {
          return errors[field][0]; 
        }
        return null;
    };

    return (
        <>
            <div className="step-supplier-information">
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="StepSupplierInformationName" className="mb-2">
                            <Form.Label>
                                <span> <i className="bi bi-house"></i> </span>
                                Nom
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nom du fournisseur"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                autoFocus
                                isInvalid={!!showError('name')}
                                />
                            <Form.Control.Feedback type="invalid">
                                {showError('name')}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>


                    <Col md={6}>
                        <Form.Group controlId="StepSupplierInformationPhone" className="mb-2">
                            <Form.Label>
                                <span> <i className="bi bi-phone"></i> </span>
                                Téléphone
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="phone"
                                placeholder="Téléphone"
                                value={formData.phone}
                                onChange={handleChange}
                                isInvalid={!!showError('contact.phone')}
                                />
                            <Form.Control.Feedback type="invalid">
                                {showError('contact.phone')}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>


                    <Col md={6}>
                        <Form.Group controlId="StepSupplierInformationLocation" className="mb-2">
                            <Form.Label>
                                <span> <i className="bi bi-map"></i> </span>
                                Lieu
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Lieu"
                                name="location"
                                value={formData.location}
                                onChange={handleChange}
                                isInvalid={!!showError('location')}
                                />
                            <Form.Control.Feedback type="invalid">
                                {showError('location')}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group controlId="StepSupplierInformationFacebookUserName" className="mb-2">
                            <Form.Label>
                                <span> <i className="bi bi-facebook"></i> </span>
                                Compte facebook
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="facebook_username"
                                placeholder="Compte facebook"
                                value={formData.facebook_username}
                                onChange={handleChange}
                                isInvalid={!!showError('contact.facebook_username')}
                                />
                            <Form.Control.Feedback type="invalid">
                                {showError('contact.facebook_username')}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group controlId="StepSupplierInformationOpeningTime" className="mb-2">
                            <Form.Label>
                                <span> <i className="bi bi-clock-history"></i> </span>
                                Heure d'ouverture
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="opening_time"
                                placeholder="Heure d'ouverture"
                                onChange={handleChange}
                                value={formData.opening_time}
                                isInvalid={!!showError('opening_time')}
                                />
                            <Form.Control.Feedback type="invalid">
                                {showError('opening_time')}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group controlId="StepSupplierInformationFacebookLink" className="mb-2">
                            <Form.Label>
                                <span> <i className="bi bi-link-45deg"></i> </span>
                                Lien Facebook
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Téléphone"
                                name="facebook_link"
                                value={formData.facebook_link}
                                onChange={handleChange}
                                isInvalid={!!showError('contact.facebook_link')}
                                />
                            <Form.Control.Feedback type="invalid">
                                {showError('contact.facebook_link')}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                </Row>
            </div>
        </>
    );
}

export default StepSupplierInformation;