import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import "../../assets/css/client/home/header.scss"


const Header = () => {
    return (
        <Navbar variant="default" bg="light" expand="lg" className="header fixed-top">
            <Container className="menu-container">
                <img src="/assets/img/Asako.png" alt="logo" width="75"/>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="toggler"/>
                <Navbar.Collapse id="basic-navbar-nav" className="menu-right"> 
                    <Nav className="ml-auto menu-right-item">
                        <Nav.Link className="link" href="#offer">Offres</Nav.Link>
                        <Nav.Link className="link" href="#about">À propos</Nav.Link>
                        <Nav.Link className="link" href="#contact">Nous contacter</Nav.Link>
                        <Nav.Link className="link" href="/login">
                            <button className="btn-connect" >
                                Se Connecter
                            </button>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
export default Header