import React, { useEffect, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from "react-quill";
import projectService from "services/projectService";
import quillConfig from "config/quillConfig";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AddProjectModal = ({loadData}) => {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [type, setType] = useState("");
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [errors, setErrors] = useState({
        name: [],
        description: [],
        type: []
    });
    
    const history = useHistory();
    const quillModule = quillConfig.getQuillModuleForProject();
    const quillFormat = quillConfig.getQuillFormatForProject();
    
    const [types, setTypes] = useState([]);
    useEffect(() => {
        projectService.getAllTypeProject()
        .then(result => setTypes(result))
        .catch(error => console.error(error));
    }, []);
    
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setErrors({});
        setShow(false);
    };
    const handleShow = () => setShow(true);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoadingSubmit(true);
        const data = {name: name, description: description, project_type_id: type}
        projectService.createProject(data)
        .then(result => {
            history.push(`/projects/${result.id}`);
        })
        .catch(error => {
            setErrors(error.response.data.errors || {});
        })
        .finally(() => setLoadingSubmit(false));
    }

    const showError = (field) => {
        if (errors[field] && errors[field].length > 0) {
          return errors[field][0]; 
        }
        return null;
    };


    return (
        <>
            <button className="asako-btn asako-btn-primary mt-4" onClick={handleShow}>
                <i className="bi bi-plus"></i> Ajouter un projet
            </button>

            <Modal show={show} onHide={handleClose} centered dialogClassName="asako__modal"> 
                <Form onSubmit={handleSubmit} className="project-form">
                    <Modal.Header className="header">
                        <Modal.Title>Ajouter un Projet</Modal.Title>
                        <i className="bi bi-x-lg" onClick={handleClose}></i>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="AddProjectFormName" className="project__form--group mb-3">
                        <Form.Label>
                            <span> <i className="bi bi-house"></i> </span>
                            Nom du projet
                        </Form.Label>
                        <Form.Control
                            type="text"
                            autoFocus
                            placeholder="Nom du projet"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            className="project__form--input"
                            isInvalid={!!showError('name')}
                        />
                        <Form.Control.Feedback type="invalid">
                            {showError('name')}
                        </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="AddProjectFormType" className="project__form--group mb-3">
                            <Form.Label className="project__form--label">
                                <span> <i className="bi bi-tags"></i> </span>
                                Type de projet
                            </Form.Label>
                            <Form.Select 
                                className="form-control project__form--input"
                                value={type}
                                onChange={e => setType(e.target.value)}
                                isInvalid={!!showError('project_type_id')}
                            >
                                <option>Choisissez le type du projet</option>
                                {types.map((item) => 
                                    <option value={item.id} key={item.id}>{item.name}</option>
                                )}                            
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                    {showError('project_type_id')}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="AddProjectFormDescription" className="project__form--group description-form mb-3">
                        <Form.Label>
                            <span> <i className="bi bi-info-circle"></i> </span>
                            Description
                        </Form.Label>
                        <ReactQuill
                            theme="snow"
                            value={description}
                            onChange={(value) => setDescription(value)}
                            modules={quillModule}
                            formats={quillFormat}
                            placeholder="Écrivez la description ici..."
                        />
                        <Form.Control.Feedback type="invalid">
                            {showError('description')}
                        </Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="asako-btn asako-btn-secondary" onClick={handleClose} type="button" > Fermer </button>
                        <button className="asako-btn asako-btn-primary" disabled={loadingSubmit}> Ajouter {loadingSubmit && <Spinner size="sm"/>}</button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default AddProjectModal;