import React, { useState } from "react";

import { Card, Container, Spinner } from "react-bootstrap";

import StepSupplierInformation from "components/admin/StepSupplierInformation";
import StepSupplierProduct from "components/admin/StepSupplierProduct";
import RecapStepAddSupplier from "components/admin/RecapStepAddSupplier";
import supplierService from "services/supplierService";
import { useHistory } from "react-router-dom";
import '../../assets/css/admin/add-supplier-page.scss';
import { useParams } from "react-router-dom";

const AddSupplierPage = () => {
    const { projectId } = useParams();
    const history = useHistory();
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        location: "",
        facebook_username: "",
        opening_time: "",
        facebook_link: "",
    });
    const [supplierProductsData, setSupplierProductsData] = useState([]);
    const [step, setStep] = useState(0);
    const [errors, setErrors] = useState({});
    const stepText = [
        "Les Informations du fournissseur",
        "Les produits vendues",
        "Résumé"
    ];

    const [loading, setLoading] = useState(false);
    const [loadingFinish, setLoadingFinish] = useState(false);

    const nextStep = async () => {
        setLoading(true);
        if (step === 0) {
            const dataToValidate = {
                name: formData.name,
                location: formData.location,
                opening_time: formData.opening_time,
                contact: {
                    phone: formData.phone,
                    facebook_username: formData.facebook_username,
                    facebook_link: formData.facebook_link
                }
            }
            supplierService.validateSupplierInformation(dataToValidate)
            .then(result => setStep(step + 1))
            .catch(error => setErrors(error.response.data.errors))
            .finally(() => setLoading(false));
        } else {
            setStep(step + 1);
        }
        
    }
    const previousStep = () => setStep(step - 1);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFinish = async () => {
        setLoadingFinish(true);
        const data = {
            name: formData.name,
            location: formData.location,
            opening_time: formData.opening_time,
            project_id: projectId,
            contact: {
                phone: formData.phone,
                facebook_username: formData.facebook_username,
                facebook_link: formData.facebook_link
            },
            products: supplierProductsData
        }
        supplierService.createSupplier(data)
        .then(result => history.push(`/projects/${projectId}/suppliers/${result.id}`))
        .catch(error => console.error(error))
        .finally(() => setLoadingFinish(false));
    }

    return (
        <>
            <Container>
                <Card className="supplier__wizard">
                    <Card.Header>
                        <span className="step">Étape {step + 1}</span> - {stepText[step]}
                    </Card.Header>
                    <Card.Body>
                        {step === 0 && <StepSupplierInformation formData={formData} handleChange={handleChange} errors={errors}/>}
                        {step === 1 && <StepSupplierProduct supplierProductsData={supplierProductsData} setSupplierProductsData={setSupplierProductsData} errors={errors}/> }
                        {step === 2 && <RecapStepAddSupplier information={formData} products={supplierProductsData}/>}
                    </Card.Body>
                    <Card.Footer className="--footer" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {step > 0 && <button className="asako-btn asako-btn-primary" onClick={previousStep}>Précédent</button>}
                        {step < 2 && <button className="asako-btn asako-btn-primary" onClick={nextStep} disabled={loading}>Suivant {loading && <Spinner size="sm"/>}</button>}
                        {step >= 2 && <button className="asako-btn asako-btn-primary" onClick={handleFinish} disabled={loadingFinish}>Terminer {loadingFinish && <Spinner size="sm"/>}</button>}
                    </Card.Footer>
                </Card>
            </Container>
        </>
    );
}
export default AddSupplierPage;