import React from "react";
import { Carousel } from "react-bootstrap"
import '../../assets/css/client/home/homeCarousel.css'

const HomeCarousel = () => {
    const baseImg = "/home/";
    const carouselImages = ["page.png"];
    const data = [
        {
            img: "page.png",
            title: "Gestions de tâches",
            description: "Facilite la districution des tâches via des colonnes et des cartes"
        },
        {
            img: "dance.jpg",
            title: "Mouvement de caisse",
            description: "Dispose d'une gestion de caisse incroyablement rapide et précis"
        },
        {
            img: "nature.jpg",
            title: "Application Mobile",
            description: "Intègre une application mobile permettant de sauvegarder les mouvements hors-ligne"
        }
    ]

    return (
        <Carousel className="carousel-container" interval={5000} pause="hover">
            {data.map((element, index) => 
            <Carousel.Item key={index}>
                <img src={`${baseImg}${element.img}`} alt="carousel" />
                <Carousel.Caption>
                <h3>{element.title}</h3>
                <p>{element.description}</p>
                </Carousel.Caption>
            </Carousel.Item>
            )}
        </Carousel>
    )
}

export default HomeCarousel