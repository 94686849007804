import React from "react";

const Land = () => {
    return (
        <>
            <div className="land">
                <div className="image">
                    <img src="/assets/img/login.png" alt="" />
                </div>
                <div className="presentation">
                    <h3>Asa'ko</h3>
                    <p>Vous avez une entreprise, une société ou un groupe, <span>Asa'ko</span> est une plateforme web permettant de gérer <span>vos calculs d'argents</span> et les <span>tâches</span> à distribuer pour chaque membre de l'équipe.</p>
                    <p>Mais encore, <span>Asa'ko</span> dispose d'une application permettant <span>les mouvements de caisses à distance </span> et même <span>hors ligne</span> pour ne pas avoir à se soucier des déplacements</p>
                </div>
                
            </div>
        </>
    )
}

export default Land;