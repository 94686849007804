import './App.scss';
import React from 'react';
import "bootstrap-icons/font/bootstrap-icons.css";
import "./index.scss";
import { Route, Switch, Redirect } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/client/home/Home';
import Login from './pages/client/home/Login';
import Sign from './pages/client/home/Sign';
import ProjectRoute from './routes/ProjectRoute';
import DefinePassword from 'pages/client/home/DefinePassword';
import { ProfilProvider } from 'contexts/ProfilContext';

export default function App() {
  return (
    <> 
      <ProfilProvider>
        <ToastContainer limit={3}/>
        <Switch>
            <Route path="/accueil" component={ Home } />
            <Route path="/members/define-password/:token" component={ DefinePassword } />
            <Route path="/login" component={ Login } />
            <Route path="/sign" component={ Sign } />
            <Route path="/projects/:path?" component={ ProjectRoute } />
            <Redirect to="/accueil"/>  
        </Switch>
      </ProfilProvider>
    </>
  );
}
