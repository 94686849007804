import React from "react"
import Header from "../../../components/home/Header"
import HomeCarousel from "../../../components/home/HomeCarousel"
import "../../../assets/css/client/home/home.scss";
import "../../../assets/css/client/home/offer.scss";
import Offer from "../../../components/home/Offer";
import Contact from "../../../components/home/Contact";
import Land from "components/home/Land";
import FeatureList from "components/home/FeatureList";


const Home = () => {

    return (
        <>  
            <Header />
            <div className="offer-container" id="offer">
                <div className="content">
                    {/* <Land /> */}
                    <HomeCarousel />
                    <Offer />
                </div>
                <div id="offer" />
            </div>
            <FeatureList />
            <Contact />
        </>
    )

}
export default Home